import { emailFormatTemplate } from './emailFormatTemplate';
export const sendEmail = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
  howDidYouFindUs,
  subject,
  message,
  date,
}) => {
  const baseUrl = process.env.GATSBY_MAILGUN_BASE_URL;
  const user = process.env.GATSBY_MAILGUN_USER;
  const apiKey = process.env.GATSBY_MAILGUN_API_KEY;
  const userDomain = process.env.GATSBY_MAILGUN_USER_DOMAIN;
  const domain = process.env.GATSBY_MAILGUN_DOMAIN;
  const emailToRedirect = process.env.GATSBY_MAILGUN_SEND_EMAIL_TO;

  const auth = 'Basic ' + window.btoa(`${user}:${apiKey}`);
  const headerEmail = new Headers();
  headerEmail.append('Authorization', auth);

  const formdataToSend = new FormData();
  const emailSubject = `${firstName} ${lastName} desea contactar con Dima para ${subject}`;

  const dataContact = {
    firstName,
    lastName,
    email,
    phoneNumber,
    howDidYouFindUs,
    subject,
    message,
    date,
  };

  const emailBody = emailFormatTemplate(dataContact);

  formdataToSend.append(
    'from',
    `Contact From Website <${userDomain}@${domain}>`
  );
  formdataToSend.append('to', emailToRedirect);
  formdataToSend.append('subject', emailSubject);
  formdataToSend.append('html', emailBody);

  const requestOptions = {
    method: 'POST',
    headers: headerEmail,
    body: formdataToSend,
  };

  try {
    const endPointToSendEmail = `${parseUrl(baseUrl)}/${domain}/messages`;
    const resp = await fetch(endPointToSendEmail, requestOptions);
    const respParsed = await resp.json();
    if ('id' in respParsed && 'message' in respParsed) {
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
        error: 'Somenthing went wrong',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      error,
    };
  }
};

const parseUrl = (url = '') => {
  return url.endsWith('/') ? url.slice(0, -1) : url;
};
