import React, { useState } from 'react';
import MaskedInput from 'react-maskedinput';
import { useIntl } from 'gatsby-plugin-intl';
import { useForm } from '../../hooks/useForm';
import { sendEmail } from '../../helpers/sendEmail';
import Spinner from '../Spinner';

const Form = () => {
  //#region State
  const intl = useIntl();
  const { values: formValues, handleInputChange, reset: resetForm } = useForm({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    howDidYouFindUs: '',
    subject: '',
    message: '',
  });
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    howDidYouFindUs,
    subject,
    message,
  } = formValues;
  const [existFieldRequiredEmpty, setExistFieldRequiredEmpty] = useState({
    value: false,
    error: '',
  });
  const [sentSuccessfully, setSentSuccessfully] = useState({
    value: false,
    message: '',
  });
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  //#endregion State
  //#region Fixed Properties
  const optHowDidYouFindUs = [
    {
      description: intl.formatMessage({
        id: 'formContact.howDidYouFindUs.0.description',
      }),
      value: intl.formatMessage({ id: 'formContact.howDidYouFindUs.0.value' }),
    },
    {
      description: intl.formatMessage({
        id: 'formContact.howDidYouFindUs.1.description',
      }),
      value: intl.formatMessage({ id: 'formContact.howDidYouFindUs.1.value' }),
    },
    {
      description: intl.formatMessage({
        id: 'formContact.howDidYouFindUs.2.description',
      }),
      value: intl.formatMessage({ id: 'formContact.howDidYouFindUs.2.value' }),
    },
    {
      description: intl.formatMessage({
        id: 'formContact.howDidYouFindUs.3.description',
      }),
      value: intl.formatMessage({ id: 'formContact.howDidYouFindUs.3.value' }),
    },
    {
      description: intl.formatMessage({
        id: 'formContact.howDidYouFindUs.4.description',
      }),
      value: intl.formatMessage({ id: 'formContact.howDidYouFindUs.4.value' }),
    },
    {
      description: intl.formatMessage({
        id: 'formContact.howDidYouFindUs.5.description',
      }),
      value: intl.formatMessage({ id: 'formContact.howDidYouFindUs.5.value' }),
    },
  ];
  //#endregion Fixed Properties
  //#region Handlers
  const handleSubmit = e => {
    e.preventDefault();
    if (isFormValid()) {
      setIsSendingEmail(true);
      sendEmail({
        ...formValues,
        date: new Date().toISOString(), //Esto se puede pasar como date y en el sendEmail. Parsear la fecha paara que se vea mejor
      })
        .then(resp => {
          if (resp.ok) {
            setSentSuccessfully({
              value: true,
              message: intl.formatMessage({ id: 'formContact.validate.3' }),
            });
            resetForm();
          } else {
            setSentSuccessfully({
              value: false,
              message: intl.formatMessage({ id: 'formContact.validate.4' }),
            });
          }
        })
        .catch(error => {
          setSentSuccessfully({
            value: false,
            message: error,
          });
        })
        .finally(() => {
          setIsSendingEmail(false);
          setExistFieldRequiredEmpty({
            value: false,
            error: '',
          });
        });
    }
  };
  //#endregion Handlers

  const isFormValid = () => {
    let result = true;
    const valuesToValidate = [
      firstName.trim().length <= 0,
      lastName.trim().length <= 0,
      email.trim().length <= 0,
      phoneNumber.trim().length <= 0,
      howDidYouFindUs.trim().length <= 0,
      subject.trim().length <= 0,
      message.trim().length <= 0,
    ];
    const inputNameToValidate = [
      `${intl.formatMessage({ id: 'formContact.firstName' }).toLowerCase()}`,
      `${intl.formatMessage({ id: 'formContact.lastName' }).toLowerCase()}`,
      `${intl.formatMessage({ id: 'formContact.email' }).toLowerCase()}`,
      `${intl.formatMessage({ id: 'formContact.phoneNumber' }).toLowerCase()}`,
      `${intl
        .formatMessage({ id: 'formContact.howDidYouFindUs.0.description' })
        .toLowerCase()}`,
      `${intl.formatMessage({ id: 'formContact.subject' }).toLowerCase()}`,
      `${intl.formatMessage({ id: 'formContact.message' }).toLowerCase()}`,
    ];
    const indexValueOnArray = valuesToValidate.indexOf(true);
    if (indexValueOnArray !== -1) {
      const error = `${intl.formatMessage({ id: 'formContact.validate.0' })} ${
        inputNameToValidate[indexValueOnArray]
      } ${intl.formatMessage({ id: 'formContact.validate.1' })}`;
      setExistFieldRequiredEmpty({
        value: true,
        error,
      });
      result = false;
    } else if (howDidYouFindUs === 'default') {
      const error = `${intl.formatMessage({
        id: 'formContact.validate.2',
      })} ${intl
        .formatMessage({ id: 'formContact.howDidYouFindUs.0.description' })
        .toLowerCase()}`;
      setExistFieldRequiredEmpty({
        value: true,
        error,
      });
      result = false;
    }
    return result;
  };

  return (
    <form className="w-full lg:w-3/5 lg:pr-16" onSubmit={handleSubmit}>
      <div className="flex flex-col items-center text-center md:text-left mt-6 lg:mt-0">
        <h3 className="w-10/12 md:w-full font-extrabold text-xl text-primary-navy">
          {intl.formatMessage({ id: 'formContact.title' })}
        </h3>
        <h1 className="w-10/12 md:w-full mt-3 text-4xl md:text-6xl font-bold text-primary-navy">
          {intl.formatMessage({ id: 'formContact.header.0' })}
          <span className="text-primary-black">
            {' '}
            {intl.formatMessage({ id: 'formContact.header.1' })}{' '}
          </span>
          {intl.formatMessage({ id: 'formContact.header.2' })}
        </h1>
        <p className="w-11/12 md:w-full mt-3 mb-5 text-sm lg:text-base text-primary-black ">
          {intl.formatMessage({ id: 'formContact.description.title' })}
          <span style={{ display: 'block' }}>
            <span className="mr-2">
              {intl.formatMessage({
                id: 'formContact.description.email.title',
              })}
              :
            </span>
            <span className="font-bold">
              {intl.formatMessage({
                id: 'formContact.description.email.value',
              })}
            </span>
          </span>
          <span style={{ display: 'block' }}>
            <span className="mr-2">
              {intl.formatMessage({
                id: 'formContact.description.phoneNumber.title',
              })}
              :
            </span>
            <span className="font-bold">
              {intl.formatMessage({
                id: 'formContact.description.phoneNumber.value',
              })}
            </span>
          </span>
        </p>
      </div>
      {sentSuccessfully.value &&
      sentSuccessfully.message.trim().length !== 0 ? (
        <div className="flex">
          <p className="text-base font-medium text-green-700 mb-2">
            {sentSuccessfully.message}
          </p>
        </div>
      ) : (
        <div className="flex">
          <p className="text-base font-medium text-red-700 mb-2">
            {sentSuccessfully.message}
          </p>
        </div>
      )}
      {existFieldRequiredEmpty.value && (
        <div className="flex">
          <p className="text-base font-normal text-red-700">
            {existFieldRequiredEmpty.error}
          </p>
        </div>
      )}
      <div className="w-full xl:w-3/5">
        <input
          name="firstName"
          id="firstName"
          type="text"
          placeholder={intl.formatMessage({ id: 'formContact.firstName' })}
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:border-opacity-100 focus:outline-none focus:ring-0 transition duration-300"
          value={firstName}
          onChange={handleInputChange}
          tabIndex={1}
          autoComplete="off"
          required
        />
        <input
          name="lastName"
          id="lastName"
          type="text"
          placeholder={intl.formatMessage({ id: 'formContact.lastName' })}
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:border-opacity-100 focus:outline-none focus:ring-0 transition duration-300"
          value={lastName}
          onChange={handleInputChange}
          tabIndex={2}
          autoComplete="off"
          required
        />
        <input
          name="email"
          id="email"
          type="email"
          placeholder={intl.formatMessage({ id: 'formContact.email' })}
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:border-opacity-100 focus:outline-none focus:ring-0 transition duration-300"
          value={email}
          onChange={handleInputChange}
          tabIndex={3}
          required
        />
        <MaskedInput
          name="phoneNumber"
          id="phoneNumber"
          type="tel"
          placeholder={intl.formatMessage({ id: 'formContact.phoneNumber' })}
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:border-opacity-100 focus:outline-none focus:ring-0 transition duration-300"
          value={phoneNumber}
          onChange={handleInputChange}
          mask="\+\1 (111) 111-1111"
          autoComplete="off"
          tabIndex={4}
        />
        <select
          name="howDidYouFindUs"
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:outline-none focus:ring-0 transition duration-300"
          value={howDidYouFindUs}
          onChange={handleInputChange}
          tabIndex={5}
          required
        >
          {optHowDidYouFindUs.map((opt, index) => (
            <option key={index} value={opt.value}>
              {opt.description}
            </option>
          ))}
        </select>
        <input
          name="subject"
          id="subject"
          type="text"
          placeholder={intl.formatMessage({ id: 'formContact.subject' })}
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:border-opacity-100 focus:outline-none focus:ring-0 transition duration-300"
          value={subject}
          onChange={handleInputChange}
          autoComplete="off"
          tabIndex={6}
          required
        />
        <textarea
          name="message"
          id="message"
          rows="3"
          placeholder={intl.formatMessage({ id: 'formContact.message' })}
          className="block w-full mb-4 border-t-0 border-r-0  border-l-0 border-b-2 border-primary-navy border-opacity-40 focus:border-opacity-100 focus:outline-none focus:ring-0 transition duration-300"
          value={message}
          onChange={handleInputChange}
          autoComplete="off"
          tabIndex={7}
          required
        />
        <button
          name="sendForm"
          type="submit"
          className="px-10 py-2 w-full rounded-md bg-primary-navy text-center text-lg font-medium text-primary-white focus:outline-none focus:ring-0 transition duration-300 hover:bg-primary-black"
          tabIndex={8}
        >
          {intl.formatMessage({ id: 'formContact.sendForm' })}
          {isSendingEmail && <span><Spinner /></span>}
        </button>
      </div>
    </form>
  );
};

export default Form;
