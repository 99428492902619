const getDateFormatted = (dateToFormatted) => {
  const day = dateToFormatted.getDate();
  const month = dateToFormatted.getMonth() + 1;
  const year = dateToFormatted.getFullYear();
  const result = `${month}/${day}/${year}`;
  return result;
};

const getPhoneNumberFormatted = (phoneNumber) => {
  const WithoutLeftPar = phoneNumber.replace('(', '');
  const WithoutRightPar = WithoutLeftPar.replace(')', '');
  const WithoutMinus = WithoutRightPar.replace('-', '');
  const WithoutSpace = WithoutMinus.replaceAll(' ', '');
  const result = WithoutSpace;
  return result;
};

// Este html fue generado usando la herramienta mjml (v4.7.1). See https://mjml.io/ for more details

export const emailFormatTemplate = ({
  date,
  firstName,
  lastName,
  email,
  phoneNumber,
  howDidYouFindUs,
  subject,
  message
}) => {
  const fullName = `${firstName} ${lastName}`;
  const phoneNumberFormatted = getPhoneNumberFormatted(phoneNumber);
  const dateFormatted = getDateFormatted(new Date(date));
  const URL_IMG_HEADER = 'https://res.cloudinary.com/dggxvus92/image/upload/v1617933686/Dima/Dima-Painter-navy-blue-rec_cdgmwp.png'
  return `<!doctype html>
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
    <title>
    </title>
    <!--[if !mso]><!-- -->
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <!--<![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <style type="text/css">
      #outlook a {
        padding: 0;
      }
  
      body {
        margin: 0;
        padding: 0;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
      }
  
      table,
      td {
        border-collapse: collapse;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
      }
  
      img {
        border: 0;
        height: auto;
        line-height: 100%;
        outline: none;
        text-decoration: none;
        -ms-interpolation-mode: bicubic;
      }
  
      p {
        display: block;
        margin: 13px 0;
      }
    </style>
    <!--[if mso]>
          <xml>
          <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
    <!--[if lte mso 11]>
          <style type="text/css">
            .mj-outlook-group-fix { width:100% !important; }
          </style>
          <![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700" rel="stylesheet" type="text/css">
    <style type="text/css">
      @import url(https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700);
    </style>
    <!--<![endif]-->
    <style type="text/css">
      @media only screen and (min-width:480px) {
        .mj-column-per-100 {
          width: 100% !important;
          max-width: 100%;
        }
      }
    </style>
    <style type="text/css">
      @media only screen and (max-width:480px) {
        table.mj-full-width-mobile {
          width: 100% !important;
        }
  
        td.mj-full-width-mobile {
          width: auto !important;
        }
      }
    </style>
  </head>
  
  <body>
    <div style="">
      <!--[if mso | IE]>
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]-->
      <div style="margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;text-align:center;">
                <!--[if mso | IE]>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  
          <tr>
        
              <td
                 class="" style="vertical-align:top;width:600px;"
              >
            <![endif]-->
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                            <tr>
                              <td style="width:300px;">
                                <img height="auto" src="${URL_IMG_HEADER}" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="300" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <p style="border-top:solid 4px #1c1853;font-size:1px;margin:0px auto;width:100%;">
                        </p>
                        <!--[if mso | IE]>
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 4px #1c1853;font-size:1px;margin:0px auto;width:550px;" role="presentation" width="550px"
          >
            <tr>
              <td style="height:0;line-height:0;">
                &nbsp;
              </td>
            </tr>
          </table>
        <![endif]-->
                      </td>
                    </tr>
                  </table>
                </div>
                <!--[if mso | IE]>
              </td>
            
          </tr>
        
                    </table>
                  <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--[if mso | IE]>
            </td>
          </tr>
        </table>
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]-->
      <div style="margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:20px 0;padding-top:0px;text-align:center;">
                <!--[if mso | IE]>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  
          <tr>
        
              <td
                 class="" style="vertical-align:top;width:600px;"
              >
            <![endif]-->
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Arial;font-size:24px;line-height:1;text-align:center;color:#1c1853;">You have got a new message from your website</div>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <p style="border-top:solid 4px #1c1853;font-size:1px;margin:0px auto;width:100%;">
                        </p>
                        <!--[if mso | IE]>
          <table
             align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 4px #1c1853;font-size:1px;margin:0px auto;width:550px;" role="presentation" width="550px"
          >
            <tr>
              <td style="height:0;line-height:0;">
                &nbsp;
              </td>
            </tr>
          </table>
        <![endif]-->
                      </td>
                    </tr>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <div style="font-family:Arial;font-size:20px;line-height:1;text-align:left;color:#1c1853;">Contact Form</div>
                      </td>
                    </tr>
                  </table>
                </div>
                <!--[if mso | IE]>
              </td>
            
          </tr>
        
                    </table>
                  <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--[if mso | IE]>
            </td>
          </tr>
        </table>
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]-->
      <div style="margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                <!--[if mso | IE]>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  
          <tr>
        
              <td
                 class="" style="vertical-align:top;width:600px;"
              >
            <![endif]-->
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="color:#000000;font-family:Arial;font-size:16px;line-height:22px;table-layout:auto;width:100%;border:none;">
                          <tr style="border-width: 1px 0px 1px 0px; border-style: solid; border-color: #1c1853;">
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold; width: 35%">Date:</td>
                            <td style="padding: 0 15px; font-weight: bold;">${dateFormatted}</td>
                          </tr>
                          <tr style="border-width: 0px 0px 1px 0px; border-style: solid; border-color: #1c1853;">
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold;">Fullname:</td>
                            <td style="padding: 0 15px; font-weight: bold;">${fullName}</td>
                          </tr>
                          <tr style="border-width: 0px 0px 1px 0px; border-style: solid; border-color: #1c1853;">
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold;">Email:</td>
                            <td style="padding: 0 15px;">
                              <mj-text text-decoration="none">
                                <a href="mailto:${email}" target="_blank">${email}</a>
                              </mj-text>
                            </td>
                          </tr>
                          <tr style="border-width: 0px 0px 1px 0px; border-style: solid; border-color: #1c1853;">
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold;">Phone number:</td>
                            <td style="padding: 0 15px;">
                              <mj-text>
                                <a href="tel:${phoneNumberFormatted}" target="_blank">${phoneNumberFormatted}</a>
                              </mj-text>
                            </td>
                          </tr>
                          <tr style="border-width: 0px 0px 1px 0px; border-style: solid; border-color: #1c1853;">
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold;">How did you find us?:</td>
                            <td style="padding: 0 15px;">${howDidYouFindUs}</td>
                          </tr>
                          <tr style="border-width: 0px 0px 1px 0px; border-style: solid; border-color: #1c1853;">
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold;">Subject:</td>
                            <td style="padding: 0 15px;">${subject}</td>
                          </tr>
                          <tr>
                            <td style="color: #1c1853; padding: 0 15px 0 0; font-weight: bold; vertical-align: top;">Message:</td>
                            <td style="padding: 0 15px;">${message}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
                <!--[if mso | IE]>
              </td>
            
          </tr>
        
                    </table>
                  <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--[if mso | IE]>
            </td>
          </tr>
        </table>
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600px;" width="600"
        >
          <tr>
            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
        <![endif]-->
      <div style="background:#1c1853;background-color:#1c1853;margin:0px auto;max-width:600px;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#1c1853;background-color:#1c1853;width:100%;">
          <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;">
                <!--[if mso | IE]>
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                  
          <tr>
        
              <td
                 class="" style="vertical-align:top;width:600px;"
              >
            <![endif]-->
                <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                        <!--[if mso | IE]>
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
        >
          <tr>
        
                <td>
              <![endif]-->
                        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;">
                          <tr>
                            <td style="padding:4px;vertical-align:middle;">
                              <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#3f729b;border-radius:3px;width:30px;">
                                <tr>
                                  <td style="font-size:0;height:30px;vertical-align:middle;width:30px;">
                                    <a href="https://www.instagram.com/dimapainter/" target="_blank">
                                      <img height="30" src="https://www.mailjet.com/images/theme/v1/icons/ico-social/instagram.png" style="border-radius:3px;display:block;" width="30" />
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td style="vertical-align:middle;">
                              <a href="https://www.instagram.com/dimapainter/" style="color:#ffffff;font-size:15px;font-family:Ubuntu, Helvetica, Arial, sans-serif;line-height:22px;text-decoration:none;" target="_blank"> Instagram </a>
                            </td>
                          </tr>
                        </table>
                        <!--[if mso | IE]>
                </td>
              
            </tr>
          </table>
        <![endif]-->
                      </td>
                    </tr>
                  </table>
                </div>
                <!--[if mso | IE]>
              </td>
            
          </tr>
        
                    </table>
                  <![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--[if mso | IE]>
            </td>
          </tr>
        </table>
        <![endif]-->
    </div>
  </body>
  
  </html>`;
};
