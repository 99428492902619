import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContactSection from '../sections/contact/ContactSection';

const ContactPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'seo.contact.title' })}
        description={intl.formatMessage({ id: 'seo.contact.description' })}
      />
      <ContactSection />
    </Layout>
  );
};

export default ContactPage;
