import React from 'react';
import Form from '../../components/Contact/Form';
import Image from '../../components/Image';

const ContactSection = () => {
  return (
    <section className="relative max-w-screen-xl mx-auto">
      <main className="flex flex-col lg:flex-row-reverse justify-between mx-auto py-5 lg:py-12 px-8">
        <div className="hidden lg:block w-full lg:w-2/5">
          <Image
            fileName="dima-painter-contact.jpg"
            className="w-9/12 lg:w-full h-2/4 lg:h-full mx-auto rounded-xl"
            alt="Dima Painter Contact us"
            title="Contact us"
          />
        </div>
        <Form />
      </main>
    </section>
  );
};

export default ContactSection;
